const propertyData = {
    "url": "http://1215NW180thAve.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "1215 NW 180th Ave", "city": "Beaverton", "state":"Oregon", "zipcode": "97006"},
    "display_address": "1215 NW 180th Ave",
    "property": {
      "listing_price": "$379,900",
      "bedrooms": "3",
      "baths": "2",
      "sq_feet": "1422",
      "lot_size": "7840.8",
      "rmls": "20337636",
      "built_in": "1976",
      "blurb": "One level corner lot ranch in well established Beaverton neighborhood. Oak hardwood floors through entry, living room, family room and dining room. Light switches are smart home programmable. High efficiency furnace with A/C. Owned solar panels & newer gutters. Private fenced backyard with workshop/storage shed, fruit trees & blueberry bushes. Convenient to schools and shopping.",
      "ammenities": [

      ]
    },
    "image_count": 20,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.088416483213!2d-122.86528278444047!3d45.52842627910173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f15003209c3%3A0x52590bbcd5e1c576!2s1215%20NW%20180th%20Ave%2C%20Beaverton%2C%20OR%2097006!5e0!3m2!1sen!2sus!4v1593056653753!5m2!1sen!2sus",
        "youtube":"dS-o9WDOFo8"
    }
  }

export default propertyData;

